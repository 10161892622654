@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.2/font/bootstrap-icons.css");
/* ---- Stylesheet ------*/

:root {
  /* --primary: #6b2b8bf0;
  --secondary: #a1ebff96;
  --ButtonHover1: #b9f0ff;
  --ButtonHover2: #611d83;
  --loader: #30c5d6; */
  --ButtonHover1: #b9f0ff;
  --ButtonHover2: #611d83;
  --primary : #a76a6a;
  --secondary: #f9e8e1;
  --loader: #30c5d6;
}
/* 
body {
  font-family: "Playfair Display", serif !important;
} */

.ptSans {
  /* font-family: "Playfair Display", serif !important; */
  font-family: "PT Sans", sans-serif !important;
}

/* --- initial Loader  ----*/

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid var(--loader);
  border-radius: 50%;
  width: 160px;
  height: 160px;
  animation: spinloader 2s linear infinite;
}
.loader img {
  animation: spinlogo 2s linear infinite;
}

.btn2:hover {
  background-color: var(--ButtonHover2);
}

@keyframes spinloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinlogo {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

/* ---End initial Loader  ----*/

.Logo {
  width: 145px;
  position: absolute;
  top: 4px;
}

.navbar-brand {
  padding: 0px;
  margin-right: 1rem !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #000;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: var(--primary) !important;
}

.font h6 {
  font-size: 15px;
  font-weight: 600;
  margin: 1px;
}
.font span {
  font-size: 10px;
}

/*--- Carousel ---*/

.thumbs.animated {
  margin: 0 !important;
}
.active_nav_bar {
  border-color: var(--primary) !important;
}
.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 3px solid var(--primary) !important;
}
.carousel .slide img {
  max-height: 600px;
  width: 100% !important;
}

.carousel .thumb img {
  width: 80px !important;
  height: 39px !important;
}

/*-- Footer ---*/
footer {
  background-image: linear-gradient(0deg, #00000029, #ffffff38),
    url("../images/footer/footerBack.webp");
}

.loginLight {
  background-image: url("../images/login/light.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
  opacity: 1;
  width: 100%;
}

.hover-trigger .hover-target {
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease 0s;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  top: 2.5rem;
}

.hover-trigger:hover .hover-target {
  visibility: visible;
  opacity: 1;
  top: 20px;
  z-index: 99999999;
}

/* ---- Card ------ */
.static-top-widget .media-body .icon-bg {
  position: absolute;
  right: -14px;
  top: 3px;
  opacity: 0.2;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100px;
  height: 100px;
  font-size: 85px;
}
.static-top-widget:hover .icon-bg {
  -webkit-transform: rotate(-5deg) scale(1.1);
  transform: rotate(-5deg) scale(1.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.position {
  bottom: 25px;
}

/*-- product Details --*/
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.react-slider__picture {
  height: 475px !important;
}
.react-slider__ul {
  margin: 5px 0 0 !important;
  justify-content: center !important;
}
.react-slider__ul li {
  height: 75px !important;
}
.react-slider__ul li.active img {
  border: 3px solid #d69e2e !important;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0px !important;
}

.capitalize::first-letter {
  text-transform: uppercase;
}

/* Like & Whish */
.hand {
  color: #fff !important;
  background-color: #5890ff !important;
}
.like {
  color: #fff !important ;
  background-color: #f25268 !important;
}
.bi-heart::before {
  content: "\f417";
  font-weight: bold !important;
}
.bi-hand-thumbs-up::before {
  content: "\f407";
  font-weight: bold !important;
}
/* End Like & Whish */

/*-- OrderItem-Box --> used to cart & checkout page ---*/
.orderItem-box {
  padding: 30px 40px;
  background-color: var(--secondary);
}
/*-- End OrderItem-Box --*/

/* ------ Hover Effects ------- */
.hoverEffect {
  background-color: var(--primary);
  color: #fff;
  transition: all 0.5s;
  border: 1px solid var(--primary) !important;
}
.hoverEffect:hover {
  background-color: var(--secondary);
  color: #000;
}

/*-- Card Remove Box Modal  double border shape ---*/
.box {
  max-width: 600px;
  padding: 5px;
  border: 2px solid #b78846;
}
.box:before,
.box:after {
  content: "•";
  position: absolute;
  width: 14px;
  height: 14px;
  font-size: 14px;
  color: #b78846;
  border: 2px solid #b78846;
  line-height: 12px;
  top: 5px;
  text-align: center;
  z-index: 2;
}
.box:before {
  left: 5px;
}
.box:after {
  right: 5px;
}
.box .box-inner {
  position: relative;
  border: 2px solid #b78846;
  padding: 40px;
}
.box .box-inner:before,
.box .box-inner:after {
  content: "•";
  position: absolute;
  width: 14px;
  height: 14px;
  font-size: 14px;
  color: #b78846;
  border: 2px solid #b78846;
  line-height: 12px;
  bottom: -2px;
  text-align: center;
}
.box .box-inner:before {
  left: -2px;
}
.box .box-inner:after {
  right: -2px;
}

.form-control:focus {
  border-color: var(--primary) !important;
  box-shadow: 0 0 0 2px var(--secondary) !important;
}

.checkPincode {
  position: absolute;
  top: 1px;
  right: 13px;
  padding: 6px 7px;
  background-color: var(--primary);
  color: #fff;
}

/*-- All Button Hover animate icon showing --*/
.button {
  background: var(--primary);
  height: 40px;
  overflow: hidden;
  text-align: center;
  transition: 0.2s;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}
.btnTwo {
  position: relative;
  width: 200px;
  height: 200px;
  margin-top: -100px;
  padding-top: 2px;
  background: var(--primary);
  left: -250px;
  transition: 0.3s;
}
.btnText {
  color: white;
  transition: 0.3s;
  font-weight: bold;
  text-transform: uppercase;
}
.btnText2 {
  margin-top: 60px;
  margin-right: -130px;
  color: #fff;
  font-size: 22px;
}

.button:hover .btnTwo {
  /*When hovering over .button change .btnTwo*/
  left: -130px;
}
.button:hover .btnText {
  /*When hovering over .button change .btnText*/
  margin-left: 65px;
  background-color: var(--ButtonHover1);
  color: #000;
  transition: all 0.5s;
  border: 1px solid var(--primary) !important;
}
.button:active {
  /*Clicked and held*/
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.3);
}
/*-- End button animate icon showing --*/

/* ---- CategoryMenu */
.dropdown-menu {
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05) !important;
  border: none;
  border-radius: 0;
  padding: 0.7em;
}
@media only screen and (min-width: 992px) {
  .dropdown:hover .dropdown-menu {
    display: block;
  }
  .dropdown-menu.show {
    display: block;
  }
}
.dropdown-menu ul {
  list-style: none;
  padding: 0;
  display: flex;
  width: 350px;
  flex-wrap: wrap;
}
.dropdown-menu li {
  width: 50%;
}
.dropdown-menu li .dropdown-item {
  color: #000;
  font-size: 13px;
  padding: 0.3em 1em;
}
.dropdown-menu li .dropdown-item:hover {
  background-color: transparent;
}
/*---- EndCategoryMenu */

@media only screen and (max-width: 992px) {
  .Logo {
    width: 115px;
  }
  .dropdown-menu.show {
    flex-wrap: wrap;
    max-height: 350px;
    overflow-y: scroll;
  }
  .dropdown-menu ul {
    list-style: none;
    padding: 0;
    display: block;
    width: 100%;
    flex-wrap: wrap;
  }

  .ttabout-img::before {
    background: var(--primary);
    bottom: 0;
    content: "";
    height: 0;
    left: auto;
    margin: 0 auto;
    position: absolute;
    transition: all 900ms ease-in-out 0s;
    -webkit-transition: all 900ms ease-in-out 0s;
    -moz-transition: all 900ms ease-in-out 0s;
    -o-transition: all 900ms ease-in-out 0s;
    -ms-transition: all 900ms ease-in-out 0s;
    z-index: 1;
  }
  .ttabout-img::after {
    width: 0px !important;
  }

  /*--- Cart Order summary --- */
  .orderItem-box {
    padding: 20px 30px;
    background-color: var(--secondary);
  }

  /*--- Header Hover --*/
  .header-cart {
    display: none !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1140px) {
  .dropdown:hover .dropdown-menu {
    width: 40vw;
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 756px) and (max-width: 1200px) {
  td {
    font-size: 13px;
  }
}
.header {
  font-size: 14px;
}
@media only screen and (min-width: 1200px) and (max-width: 1307px) {
  .header {
    font-size: 12px;
  }
  .header li a {
    padding: 8px 20px !important;
  }
}

/* ---- Scroll bar thumn ---- */

#style-11::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#style-11::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
  height: 10px;
}

#style-11::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 10px;
  background-image: -webkit-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.5) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.5) 75%,
    transparent 75%,
    transparent
  );
}
.bi-heart::before {
  content: "\f417";
  font-weight: bold !important;
}
.bi-hand-thumbs-up::before {
  content: "\f407";
  font-weight: bold !important;
}
/* End Like & Whish */

/*-- OrderItem-Box --> used to cart & checkout page ---*/
.orderItem-box{
  padding: 30px 40px;
  background-color: var(--secondary);
}
/*-- End OrderItem-Box --*/


/* --- Product Type Hover Effects --*/
.ttaboutbanner1 {
  cursor: pointer;
}
.ttaboutbanner .ttbanner-title {
  float: left;
  position: absolute;
  font-weight: bold;
  color: #111;
  letter-spacing: 0.2px;
  background: var(--secondary);
  border-radius: 0px 0px 20px;
  border-color: var(--primary);
  border-style: dashed;
  border-width: 0px 1px 1px 0px;
  padding: 10px 30px;
  opacity: 1;
  -webkit-transition: all 600ms ease-in-out 0s;
}

.ttaboutbanner:hover .ttabout-img img {
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -o-transform: scale(1.3);
  -ms-transform: scale(1.3);
}

.ttabout-img img {
  -webkit-transition: all 600ms ease-in-out 0s;
}

.ttaboutbanner .ttabout-img span {
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;
}

.ttaboutbanner .ttbanner-icon {
  writing-mode: vertical-lr;
  float: left;
  position: relative;
  font: 400 italic 20px/22px "Playfair Display", Helvetica, sans-serif;
  color: #111;
  letter-spacing: 0.2px;
  background: #fff;
  padding: 0 0 20px 0;
}

.ttabout-img::before {
  background: var(--primary);
  bottom: 0;
  content: "";
  height: 0;
  left: auto;
  margin: 0 auto;
  position: absolute;
  right: -10px;
  top: auto;
  width: 1px;
  transition: all 900ms ease-in-out 0s;
  -webkit-transition: all 900ms ease-in-out 0s;
  -moz-transition: all 900ms ease-in-out 0s;
  -o-transition: all 900ms ease-in-out 0s;
  -ms-transition: all 900ms ease-in-out 0s;
  z-index: 1;
}
.ttaboutbanner1 .ttaboutbanner:hover .ttabout-img:hover::before {
  height: 85%;
}
.ttabout-img::after {
  background: var(--secondary);
  bottom: 0;
  content: "";
  height: 93%;
  left: auto;
  margin: 0 auto;
  position: absolute;
  right: -10px;
  top: auto;
  width: 1px;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none !important;
}

.selectpicker > option:checked {
  background-color: var(--primary);
  color: #fff;
}

.custom_background {
  background-image: url(https://lakshmanaacharison.com/wp-content/uploads/2020/08/floral-design.png);
  background-position: left top;
  background-size: auto;
  background-repeat: repeat;
  background-attachment: scroll;
}

/* -------------- Policies ---------------- */

.accordion-button:focus {
  z-index: 3;
  border: 1px solid var(--primary) !important;
  outline: 0;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: var(--primary);
  background-color: var(--secondary);
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  border-radius: 10px;
  border: 1px solid var(--primary);
}

/* ---- myscheme page =>  chit description scroll ---*/
.custom_scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.custom_scroll::-webkit-scrollbar {
  width: 6px;
  background-color: var(--secondary);
}

.custom_scroll::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 10px;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}

/* --  Order Status Page style --- */

.bg-image {
  background-image: url("../images/OrderStatus/shopbg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: absolute;
  top: 0px;
  z-index: 999;
}

.social_media {
  background: linear-gradient(
      265deg,
      rgba(121, 59, 9, 0.041) 0%,
      rgba(121, 60, 9, 0.14889705882352944) 72%
    ),
    url("../images/MainpageBanners/socialMediaBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  width: 100%;
}

/*--- Tooltip ----*/

.tooltip1 {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip1 .tooltiptext {
  visibility: hidden;
  width: 40px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
}

.tooltip1 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip1:hover .tooltiptext {
  visibility: visible;
}

/*--- Product Details Hover ---*/

.role .carousel-slider {
  display: none;
}

/*--- Card Slider square buttons style---*/
.newArrivalsComponent button.rec-dot {
  display: none !important;
}
.newArrivalsComponent .rec.rec-arrow {
  background-color: transparent !important;
  color: #000;
  font-size: 20px !important;
  border: 0px !important;
  box-shadow: none !important;
}
.rec.rec-arrow {
  background-color: var(--primary) !important;
  color: #fff;
  min-width: 39px !important;
  height: 39px !important;
  width: 39px !important;
  line-height: 39px !important;
  font-size: 20px !important;
  border: 1px solid var(--primary) !important;
}

.rec.rec-arrow:hover {
  background-color: var(--primary) !important;
  color: #fff !important;
}
.rec-dot {
  background-color: var(--secondary) !important;
}
.rec-dot.rec-dot_active {
  background-color: var(--secondary) !important;
  box-shadow: 0 0 1px 3px var(--primary) !important;
}
/*---End Card Slider ---*/

/*--- Product List page => filter design ---*/
.item.amshopby-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  margin: 0;
  font-size: 14px;
  background-color: #e7eff2;
  padding: 6px 8px 6px 16px;
  border-radius: 32px;
  color: #4e4b66;
}
/*--- End Product List page => filter design ---*/

/*--- Scheme flip horizintol animation ---*/

.spinner {
  -webkit-animation: rotate-div 2.2s infinite ease-in-out;
  animation: rotate-div 2.2s infinite ease-in-out;
}

@keyframes rotate-div {
  0%,
  100% {
    transform: perspective(400px) scaleX(1);
  }

  50% {
    transform: perspective(400px) scaleX(-1);
  }
   /* {
    transform: perspective(400px) scaleX(1);
  } */
}

.button:hover .btnTwo{ /*When hovering over .button change .btnTwo*/
  left: -130px;
}
.button:hover .btnText{ /*When hovering over .button change .btnText*/
  margin-left : 65px;
  background-color:  #fbeae3;
  color: #000 ;
  transition: all 0.5s;
  border:1px solid var(--primary) !important;
}
.button:active { /*Clicked and held*/
  box-shadow: 0px 5px 6px rgba(0,0,0,0.3);
}
/*-- End button animate icon showing --*/



/* ---- CategoryMenu */
.dropdown-menu {
  box-shadow:0 0 20px 3px rgba(0, 0, 0, 0.05) !important;
  border: none;
  border-radius: 0;
  padding: 0.7em;
}
@media only screen and (min-width: 992px) {
  .dropdown:hover .dropdown-menu {
    display: block;
  }
  .dropdown-menu.show {
    display: block;
  }
}
.dropdown-menu ul {
  list-style: none;
  padding: 0;
  display: flex;
  width: 350px;
  flex-wrap: wrap;
}
.dropdown-menu li {
  width: 50%;
}
.dropdown-menu li .dropdown-item {
  color: #000;
  font-size: 13px;
  padding: 0.3em 1em;
}
.dropdown-menu li .dropdown-item:hover {
  background-color: transparent;
}
/*---- EndCategoryMenu */


@media only screen and (max-width: 992px) {
  .dropdown-menu.show {
    flex-wrap: wrap;
    max-height: 350px;
    overflow-y: scroll;
  }
  .dropdown-menu ul {
    list-style: none;
    padding: 0;
    display: block;
    width: 100%;
    flex-wrap: wrap;
  }

  .ttabout-img::before {
    background: var(--primary);
    bottom: 0;
    content: "";
    height: 0;
    left: auto;
    margin: 0 auto;
    position: absolute;
    transition: all 900ms ease-in-out 0s;
    -webkit-transition: all 900ms ease-in-out 0s;
    -moz-transition: all 900ms ease-in-out 0s;
    -o-transition: all 900ms ease-in-out 0s;
    -ms-transition: all 900ms ease-in-out 0s;
    z-index: 1;
  }
.ttabout-img::after {
 width: 0px !important;
  }


/*--- Cart Order summary --- */
.orderItem-box{
  padding: 20px 30px;
  background-color: var(--secondary);
}

/*--- Header Hover --*/
  .header-cart{
    display: none !important;
  }
}


@media only screen and (min-width: 992px) and (max-width: 1140px) {
  .dropdown:hover .dropdown-menu {
    width: 40vw;
    flex-wrap: wrap;
  }
}
.header{
  font-size: 14px;
}
@media only screen and (min-width: 1200px) and (max-width:1307px) {
.header{
 font-size: 12px;
  }
  .header li a{
    padding: 8px 20px !important;
  }
}

/* ---- Scroll bar thumn ---- */

#style-11::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

#style-11::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
  height: 10px;
}

#style-11::-webkit-scrollbar-thumb
{
  background-color: var(--primary);
  border-radius: 10px;
  background-image: -webkit-linear-gradient(0deg,
  rgba(255, 255, 255, 0.5) 25%,
  transparent 25%,
  transparent 50%,
  rgba(255, 255, 255, 0.5) 50%,
  rgba(255, 255, 255, 0.5) 75%,
  transparent 75%,
  transparent)
}


/* --- Product Type Hover Effects --*/
.ttaboutbanner1{
  cursor: pointer;
}
.ttaboutbanner .ttbanner-title {
  float: left;
  position: absolute;
  font-weight: bold;
  color: #111;
  letter-spacing: .2px;
  background: var(--secondary);
  padding: 10px 30px;
  opacity: 1;
  -webkit-transition: all 600ms ease-in-out 0s;
}


.ttaboutbanner:hover .ttabout-img img {
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -o-transform: scale(1.3);
  -ms-transform: scale(1.3);
}

.ttabout-img img{
  -webkit-transition: all 600ms ease-in-out 0s;
}

.ttaboutbanner .ttabout-img span {
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;
}

.ttaboutbanner .ttbanner-icon {
  writing-mode: vertical-lr;
  float: left;
  position: relative;
  font: 400 italic 20px/22px "Playfair Display",Helvetica,sans-serif;
  color: #111;
  letter-spacing: .2px;
  background: #fff;
  padding: 0 0 20px 0;
}

 .ttabout-img::before {
  background: var(--primary);
  bottom: 0;
  content: "";
  height: 0;
  left: auto;
  margin: 0 auto;
  position: absolute;
  right: -10px;
  top: auto;
  width: 1px;
  transition: all 900ms ease-in-out 0s;
  -webkit-transition: all 900ms ease-in-out 0s;
  -moz-transition: all 900ms ease-in-out 0s;
  -o-transition: all 900ms ease-in-out 0s;
  -ms-transition: all 900ms ease-in-out 0s;
  z-index: 1;
}
.ttaboutbanner1 .ttaboutbanner:hover .ttabout-img:hover::before {
  height: 85%;
}
.ttabout-img::after {
  background: var(--secondary);
  bottom: 0;
  content: "";
  height: 93%;
  left: auto;
  margin: 0 auto;
  position: absolute;
  right: -10px;
  top: auto;
  width: 1px;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none !important;
}

.selectpicker > 
option:checked {
  background-color: var(--primary);
  color: #fff;
  background: var(--primary);
}

.select-Box{
  position: relative;
  z-index: 2;
}

.accordion-container {
  width: 100%;
}

.accordion {
  border-bottom: 1px solid #ccc;
}

.accordion-button {
  width: 100%;
  text-align: left;
  background-color: #f1f1f1;
  padding: 15px;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.accordion-button.active {
  background-color: #ddd;
}

.panel {
  display: none;
  padding: 15px;
  background-color: white;
}

.panel.expanded {
  display: block;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .accordion-button {
    font-size: 16px;
  }

  .panel {
    padding: 10px;
  }
}

/* Digigold css start */

.digicard{

    width: 90%;
    height: 10%;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 20px;
    margin: 40px auto; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    top: 10%;
    border: 3px solid #D5B280;
    border-radius: 10px;
  
}
.imgg{
  /* background-image: url("../images/TechWallet/SL-070820-32300-21.jpg"); */
  background-color: #F1EEEA;
  background-repeat: no-repeat;
  background-position: top center;

}
.digititle{
  display: flex;
  justify-content: center;
  font-family:Arial, Helvetica, sans-serif;
  font-weight: 700;
}
.digirow{
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
/* .digitext{
  font-weight: 600;
} */

.forms {
	width: 149px;
	margin: 50px 1px;
}

.form-group {
	position: relative;
	margin-bottom: 45px;
  margin-left : 3px;
}

.digiinput {
	display: block;
	width: 149px;
	font-size: 16px;
	padding: 5px;
	border: none;
	border-bottom: 1px solid #ccc;
  background-color: #F1EEEA;

}

input:focus {
	outline: none;
}

.labels {
	position: absolute;
	top: 10px;
	left: 5px;
	color: #999;
	font-size: 14pt;
	font-weight: normal;
	pointer-events: none;
	transition: all 0.2s ease;
}

/* input:focus ~ .labels,
input:valid ~ .labels {
	top: -20px;
	font-size: 10pt;
	color: #5264AE;
} */

.bar {
  width: 260px;
  height: 2px;
  background: #5264AE;
}

.amount-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -22px;
}

.amount-button {
  padding: 1px 13px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.amount-button:hover {
  background-color: #f0f0f0;
}

@media screen and (max-width: 600px) {
  .container {
    max-width: 95%;
  }

  .amount-buttons {
    flex-direction: column;
  }

  .amount-button {
    width: 100%;
    margin-bottom: 10px;
  }
}
/* .calc{ */
  /* position: relative; */
  /* display: flex;
  align-items: center;
  justify-content:space-around;
  flex-direction: row; */
  /* padding: 10px;
  margin: 10px; */

/* } */
.horizantalcode{
width: 260px;
height: 2px;
background: cadetblue;
}

.walletimg{
  margin-top:-32px;
}
.stylebtn{
  margin-top: 49px;
  width: 260px;
  height: 52px;
  color: #ccc;
  font-family: Arial, Helvetica, sans-serif;
}
.buttonss{
  padding: 15px 25px;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: #4CC5D9;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
  width: 250px; 
  margin-top: 34px;
}

.buttonss:hover{
  background-color: #2196F3
}
.buttonss:active {
  background-color: #2196F3;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
.purity{
  /* position: absolute;
  left: 166px;
  bottom: 33px; */
  display: flex;
  justify-content: center;
  margin-top: -35px;
  margin-left: 56px;
}

.gramposition{
  position: relative;
  left: 190px;
  top:-31px;
}

.grampositions{
  display: flex;
  justify-content: flex-start;
  position: relative;
  top: -46px;
  font-weight: bold;
}

/* Digigold css end */
.points{
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
}
.totalaval{
  display: flex;
  justify-content: center;
  margin-top: 41px;
}
.totalreferral{
  font-size: 20px;
  display: flex;
  justify-content: center;
}
.getreffer{
  display: flex;
  justify-content: center;
}

.tables{
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabledata {
  width: 800px;
  height: 200px;
}

.tablehead {
  border-bottom: 1px solid black;
  text-align: center;
}

.tabledatas {
  text-align: center;
}
